import React, { useEffect, useState } from "react";

function Card(props) {
  const { className, bgColor, fontColor, title, text, image, imageLocation, split, tag, tagChild, children, isMobileOrTablet, ...rest } = props
  const [expanded, setExpanded] = useState(false)
  const isMobile = isMobileOrTablet

  useEffect(() => {
  	if(isMobile) {
  		setExpanded(true)
  	}
  }, [isMobile])

  const handleClick = () => {
  	const selObj = window?.getSelection() || null
  	if(isMobile || selObj?.type === 'Range') {
  		console.log(window?.getSelection())
  		return null
  	}
  	console.log(window?.getSelection?.type)
  	setExpanded(!expanded)
  }

  return (
  	<div
  		className={`card ${expanded ? 'expanded' : 'collapsed'} px-4 md:px-16 py-6 ${bgColor} ${fontColor} ${className}`}
  		onClick={handleClick}
  	>
  		<div className="titlerow text-4xl px-4">
  			<h1 className={`text-lg md:text-2xl mx-auto md:mx-0 ${title === "People I've Met From the Internet" ? 'italic' : null }`}>{title}</h1>
  			{isMobile ? (
  				null
  			) : (
  			  <span className="expand-icon">{ expanded ? '-' : '+' }</span>
  			)}
	  	</div>
	  	<div>
	  	</div>
	  		{image ? (
		  		imageLocation === 'left' || isMobile ? (
			  			<div className="flex-col md:flex-row flex align-center justify-between">
			  				<div className="flex-shrink-0">
				  				<img src={image} className={`w-auto  ${expanded ? 'md:w-56' : 'md:w-32' } h-auto mt-4 md:mt-0 m-0 md:m-4 card-image`} />
				  			</div>
				  			<div className="flex flex-grow">
				  				{expanded ?
				  					text === 'quotes' ? (
			  							<p className="p-4">
									        "Unputdownable. Young gay sex and super mundane details--two things I love, together."<br/>
									        -- <b>Miranda July</b> author of <i>The First Bad Man</i><br/><br/>

									        "Stephen van Dyck's meticulous sexual records reveal the true recent histories of America, the Internet, the nearly-defunct nuclear family and the author himself. Surprisingly touching, <i>People I've Met From the Internet</i> is a brilliantly written, taxonomic account of growing up queer in turn-of-the-millennium Albuquerque, Los Angeles, and beyond."<br/>
									        -- <b>Chris Kraus</b> author of <i>I Love Dick</i><br/><br/>

									        "This is an impressive work, modern, relevant, powerfully startling in its effect."<br/>
									        -- <b>John Rechy</b> author of <i>City of Night</i>  <br/><br/>

									        "Bold, brave, sexy. . .This annotated bibliography of encounters bridging the virtual and real worlds of desire feels like a nineteenth-century erotic novel transposed onto the present, filled with salacious stories and characters. A truly remarkable adventure."<br/>
									        -- <b>D. A. Powell</b> author of <i>Cocktails</i><br/><br/>

									        "A brilliant, deadpan account of sexualized youth. . .  If it wasn't so effortlessly funny and wry, <i>People I've Met From the Internet</i> would horrify; as it stands, every sentence--every checked-off box of kissing? oral? anal?--brings on the warm flush a real writer gives you." <br/>
									        -- <b>Dodie Bellamy</b> author of <i>When the Sick Rule the World</i><br/><br/>

									        "As the internet transformed the gay world from a limited number of spaces to a virtually unlimited homotopia, things were gained and things were lost, but van Dyck was one of its argonauts. . .  There's a new kind of queer text here, one needed for a new queer age."  <br/>
									        -- <b>Matias Viegener</b> author of <i>2500 Random Things About Me Too</i><br/><br/>

									        "A glowing diorama that is continuously unfolding with mountains, living, men, cities, and sex. I love the sense of absolute openness in Stephen van Dyck's <i>People I've Met From the Internet</i>, how direct it is, how witty, and at times how sweet."  <br/>
									        -- <b>Amina Cain</b> author of <i>Creature</i><br/><br/>

									        "Stephen van Dyck's <i>People I've Met From the Internet</i> is a wholly original, brilliant and engrossing book. I couldn't put it down."  <br/>
									        -- <b>Kate Durbin</b> author of <i>E! Entertainment</i><br/><br/>
									         
									        "This is no ordinary memoir. It's a moving, funny and rigorous attending to technology, desire and community as experienced by a whole generation. . .  A tour de force of post-internet life writing."  <br/>
									        -- <b>Janet Sarbanes</b> author of <i>The Protester Has Been Released</i>

									    </p>
			  						) : (
			  							<p className="p-4">
			  							   Stephen van Dyck is a writer, artist, and educator, whose first book, <i>People I've Met From the Internet</i> came out in October 2019 on Ricochet Editions. The book has received praise from Miranda July, Chris Kraus, Dodie Bellamy, John Rechy, and D. A. Powell. Zyzzyva Magazine called it "the ultimate memoir for the Digital Age." Van Dyck's work has also been written about in the Gay & Lesbian Review, LA Weekly, the Atticus Review, Fulll Stop Magazine, the LA Review of Books, Hyperallergic, and on Dennis Cooper's Blog. An MFA graduate of Integrated Media, Critical Studies and Experimental Sound Practices at CalArts, van Dyck has performed and presented work all around Los Angeles, including LACE, MOCA, LACMA and Human Resources. Since 2008, van Dyck has curated the Los Angeles Road Concerts, all-day arts events where over 300 LA artists and locals have re-imagined unused outdoor public space along the entire lengths of LA's very long streets.
			  							</p>
		  							)
				  				 : <h1 className="p-4 text-xl tagline">Stephen van Dyck is a writer, artist, and educator, and the author of <i>People I've Met From the Internet</i>, out October 2019 from Ricochet Editions. <span className="italic text-blue-500">Read more...</span></h1>
				  				}
				  			</div>
				  		</div>
			  		) : (
			  			<div className="flex-col md:flex-row flex align-center justify-between">
			  				<div className="flex">
				  				{expanded ?
				  					text === 'quotes' ? (
			  							<p className="p-4">
									        "Unputdownable. Young gay sex and super mundane details--two things I love, together."<br/>
									        -- <b>Miranda July</b> author of <i>The First Bad Man</i><br/><br/>

									        "Stephen van Dyck's meticulous sexual records reveal the true recent histories of America, the Internet, the nearly-defunct nuclear family and the author himself. Surprisingly touching, <i>People I've Met From the Internet</i> is a brilliantly written, taxonomic account of growing up queer in turn-of-the-millennium Albuquerque, Los Angeles, and beyond."<br/>
									        -- <b>Chris Kraus</b> author of <i>I Love Dick</i><br/><br/>

									        "This is an impressive work, modern, relevant, powerfully startling in its effect."<br/>
									        -- <b>John Rechy</b> author of <i>City of Night</i>  <br/><br/>

									        "Bold, brave, sexy. . .This annotated bibliography of encounters bridging the virtual and real worlds of desire feels like a nineteenth-century erotic novel transposed onto the present, filled with salacious stories and characters. A truly remarkable adventure."<br/>
									        -- <b>D. A. Powell</b> author of <i>Cocktails</i><br/><br/>

									        "A brilliant, deadpan account of sexualized youth. . .  If it wasn't so effortlessly funny and wry, <i>People I've Met From the Internet</i> would horrify; as it stands, every sentence--every checked-off box of kissing? oral? anal?--brings on the warm flush a real writer gives you." <br/>
									        -- <b>Dodie Bellamy</b> author of <i>When the Sick Rule the World</i><br/><br/>

									        "As the internet transformed the gay world from a limited number of spaces to a virtually unlimited homotopia, things were gained and things were lost, but van Dyck was one of its argonauts. . .  There's a new kind of queer text here, one needed for a new queer age."  <br/>
									        -- <b>Matias Viegener</b> author of <i>2500 Random Things About Me Too</i><br/><br/>

									        "A glowing diorama that is continuously unfolding with mountains, living, men, cities, and sex. I love the sense of absolute openness in Stephen van Dyck's <i>People I've Met From the Internet</i>, how direct it is, how witty, and at times how sweet."  <br/>
									        -- <b>Amina Cain</b> author of <i>Creature</i><br/><br/>

									        "Stephen van Dyck's <i>People I've Met From the Internet</i> is a wholly original, brilliant and engrossing book. I couldn't put it down."  <br/>
									        -- <b>Kate Durbin</b> author of <i>E! Entertainment</i><br/><br/>
									         
									        "This is no ordinary memoir. It's a moving, funny and rigorous attending to technology, desire and community as experienced by a whole generation. . .  A tour de force of post-internet life writing."  <br/>
									        -- <b>Janet Sarbanes</b> author of <i>The Protester Has Been Released</i>

									    </p>
			  						) : (
			  							<p className="p-4">{text}</p>
		  							)
				  				 : <div className="mb-48">
				  				 			{tagChild ?
				  				 				tagChild
				  				 			: <h1 className="p-4 text-xl tagline">{tag} <span className="italic text-blue-500">read more...</span></h1>}
				  				 		</div>
				  				}
				  			</div>
				  			<div className="flex h-64 px-4 flex-shrink-0">
				  				<img src={image} className={`w-auto ${expanded ? 'md:w-64 book-height' : 'md:w-56' } h-auto mt-4 md:mt-0 m-0 card-image`} />
				  			</div>
				  		</div>
			  	)
	  		) : (
	  			<div className="flex">
		  			<p className="p-4">{text}</p>
		  		</div>
	  		)}
	  		{children}
  		</div>

  )
}
export default Card