import React, { useState } from "react";

function CardSmall(props) {
  const { className, bgColor, fontColor, title, text, image, imageLocation, split, children, ...rest } = props
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
  	setExpanded(!expanded)
  }

  return (
  	<div
  		className={`card-small md:w-3/6 w-auto md:px-16 px-4 md:px-0 py-6 ${bgColor} ${fontColor} ${className}`}
  		onClick={handleClick}
  	>
  		<div className="titlerow text-4xl px-4">
  			<h1 className="text-2xl">{title}</h1>
	  	</div>
	  	{text ? (
		  	<div className="flex align-center">
				<p className="p-4">{text}</p>
			</div>
	  	) : null
	  	}
		{children}
  	</div>

  )
}
export default CardSmall