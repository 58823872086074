import React, {useEffect, useState} from "react"
import Card from "./card"
import CardSmall from "./cardSmall"
import Seo from "./seo"
import svd from "../images/svd.jpg"
import amazon from "../images/amazon.png"
import goodreads from "../images/goodreads.svg"
import instagram from "../images/instagram.svg"
import twitter from "../images/twitter.svg"
import bookImage from '../images/book.png'

import "./main.css"

const Container = () => {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)
  function detectWidth(x) {
    if (x.matches) { // If media query matches
      setIsMobileOrTablet(true)
    } else {
      setIsMobileOrTablet(false)
    }
  }

  let innerWidth = null
  let x = null

  if (typeof window !== 'undefined') {
    x = window.matchMedia("(max-width: 700px)")
    innerWidth = window.innerWidth
  }

  useEffect(() => {
    detectWidth(x)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener("resize", () => detectWidth(x));
    }
  }, [innerWidth])

  const tagChild = <div className='px-4'>
                      <p className='py-2'>"Unputdownable" -- <b>Miranda July</b></p>
                      <p className='py-2'>"Brilliantly Written" -- <b>Chris Kraus</b></p>
                      <p className='py-2'>"An impressive work" -- <b>John Rechy</b></p>
                      <p className='py-2'>one of "4 books I recently read and loved"<br/>-- <b>Dennis Cooper</b></p>
                      <span className="italic text-blue-500">Read more...</span>
                    </div>

  return (
    <div>
      <Seo title="Stephen van Dyck" description="Stephen van Dyck's People I've Met From the Internet is a queer reimagining of the coming-of-age narrative set at the dawn of the internet era." />
      <Card
        className="first-card"
        isMobileOrTablet={isMobileOrTablet}
        bgColor="white"
        fontColor="text-grey"
        title="People I've Met From the Internet"
        imageLocation="right"
        image={bookImage}
        tagChild={tagChild}
        text="quotes"
      >
      </Card>
      <Card 
        isMobileOrTablet={isMobileOrTablet}
        bgColor="white"
        fontColor="text-grey"
        title="Stephen van Dyck"
        imageLocation="left"
        image={svd}
      />
      <div className="flex split-container">
        <CardSmall
          bgColor="white"
          fontColor="text-grey"
          className="split-card split-card-left"
          title="Purchase"
        >
          <a
            href="https://bookshop.org/books/people-i-ve-met-from-the-internet/9781938900259 "
            className="italic text-blue-500 block px-4 my-2 mt-8"
          >
            BOOKSHOP
          </a>
          <a
            href="https://www.spdbooks.org/Products/9781938900259/people-ive-met-from-the-internet.aspx"
            className="italic text-blue-500 block px-4 my-2"
          >
            SMALL PRESS DISTRIBUTION
          </a>
          <a
            href="https://dornsife.usc.edu/goldlinepress/people-ive-met-from"
            className="italic text-blue-500 block px-4 my-2"
          >
            RICOCHET EDITIONS
          </a>
          <a
            href="https://www.indiebound.org/book/9781938900259"
            className="italic text-blue-500 block px-4 my-2"
          >
            INDIE BOUND
          </a>
          <a
            href="https://www.amazon.com/gp/product/1938900251"
            className="italic text-blue-500 block px-4 my-2"
          >
            AMAZON
          </a>
        </CardSmall>
        <CardSmall
          bgColor="white"
          fontColor="text-grey"
          title="Contact"
          className="split-card split-card-right">
          <div className="p-4">
            <a href="mailto:svandyc@gmail.com" className="italic text-blue-500">svandyc[at]gmail[dot]com</a>
            <div id="mc_embed_signup">
              <form action="https://gmail.us4.list-manage.com/subscribe/post?u=524ec61e93dea04a1e521faf1&amp;id=39c3e42931" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate="novalidate">
                <div id="mc_embed_signup_scroll">
                  <h2 className="mt-8 text-xl">Join the Mailing List</h2>
                  <div class="flex my-4 items-center">
                    <h2>Email:
                    </h2>
                    <input type="email" value="" name="EMAIL" id="mce-EMAIL" aria-required="true" className="border border-gray-500 rounded mx-4"/>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full mx-auto">
                      Submit
                    </button>
                  </div>
                  <div class="flex my-4 items-center">
                  </div>
                </div>
              </form>
            </div>
          </div>
        </CardSmall>
      </div>
      <Card
        isMobileOrTablet={true}
        bgColor="white"
        fontColor="text-grey"
        title="Upcoming Events"
        className="news-events"
      >
        <div class="px-4">
          <p className="py-2"><b>6/18/2020</b> <a className="italic text-blue-500" href="https://www.bkwrks.com/van-dyck">Bookworks (Albuquerque, NM)</a></p>
          <p className="py-2"><b>6/21/2020</b> <a className="italic text-blue-500" href="https://www.facebook.com/pg/KartonnenDozen/events/">Kartonnen Dozen (Antwerpen, Belgium)</a></p>          
          <p className="py-2"><b>6/29/2020</b> <a className="italic text-blue-500" href="https://www.left-bank.com/event/2020-06">Left Bank Books (St. Louis, MO)</a></p>
          <p className="py-2"><b>7/10/2020</b> <a className="italic text-blue-500" href="https://bluestockings.com/event/people-ive-met-from-the-internet/?instance_id=219687">Bluestockings (NYC) with Kate Durbin</a></p>
        </div>
      </Card>
      <div className="flex split-container last-card">
        <Card
          isMobileOrTablet={true}
          bgColor="white"
          fontColor="text-grey"
          title="Reviews & Interviews"
          className="news-events"
        >
<div class="copy px-4">
<p className='py-2'><a className="italic text-blue-500" href="https://denniscooperblog.com/4-books-i-read-recently-and-loved-sarah-rose-etter-the-book-of-x-stephen-van-dyck-people-ive-met-from-the-internet-lisa-marie-basile-alyssa-morhardt-goldstein-nympholepsy-eric-walker-selected">"4 books I read recently and loved"</a> at <b>Dennis Cooper's</b> Blog
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://therumpus.net/2020/02/the-rumpus-mini-interview-project-208-stephen-van-dyck/">Interview</a> with <b>Allie Rowbottom</b> at The Rumpus
    </p> 
<p className='py-2'><a className="italic text-blue-500" href="https://lareviewofbooks.org/av/literary-la-stephen-van-dyck-meets-people-internet/">Interview</a> with <b>Eric Newman</b> at LA Review of Books
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://www.zyzzyva.org/2019/07/01/people-ive-met-from-the-internet-by-stephen-van-dyck-delight-in-the-details/">Review</a> at Zyzzyva Magazine by <b>Julia Matthews</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://glreview.org/article/grindr-world">Review</a> at the Gay & Lesbian Review by <b>Chris Freeman</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://atticusreview.org/memoir-log-in/">Review</a> at the Atticus Review by <b>James Chapin</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://independentbookreview.com/2020/05/21/book-review-people-ive-met-from-the-internet/">Review</a> at the Independent Book Review by <b>Liam Anthony</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://entropymag.org/best-of-2019-nonfiction-books/">"Best of 2019: Nonfiction Books"</a> at <b>Entropy</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://independentbookreview.com/2019/12/11/2019-indie-press-books/">"30 Impressive Indie Press Books from 2019"</a> at <b>Independent Book Review</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="http://www.full-stop.net/2019/09/18/blog/allison-okeefe/trending-again/">Review</a> at Full Stop Magazine by <b>Allison O’Keefe</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://triumphofthenow.com/2019/11/13/people-ive-met-from-the-internet-by-stephen-van-dyck/">Review</a> at Triumph of the Now by <b>Scott Manley Hadley</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://www.spdbooks.org/pages/bestsellers/nonfiction/default.aspx">#2 January—March 2020</a> on <b>SPD Nonfiction Bestsellers</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="https://www.spdbooks.org/pages/bestsellers/nonfiction/nonfiction-bestsellers-archive.aspx">#2 on October—December 2019</a> on <b>SPD Nonfiction Bestsellers</b>
    </p>
<p className='py-2'><a className="italic text-blue-500" href="http://foryourart.com/recommendations/book-launch-for-stephen-van-dyck-s-people-i-ve-met-from-the-internet-in-conversation-with-matias-viegener">ForYourArt</a> recommends PIMFTI book launch at Skylight Books</p>
</div>
        </Card>
      </div>
      <div className="social-row">
        <a className="italic text-blue-500" href="https://www.goodreads.com/book/show/45889659-people-i-ve-met-from-the-internet">
          <img className="social-button" src={goodreads} />
        </a>
        <a className="italic text-blue-500" href="https://twitter.com/stephenvandyck">
          <img className="social-button" src={twitter} />
        </a>
        <a className="italic text-blue-500" href="https://www.instagram.com/stephenvandyck/">
          <img className="social-button" src={instagram} />
        </a>
        <a className="italic text-blue-500" href="https://www.amazon.com/gp/product/1938900251">
          <img className="social-button" src={amazon} />
        </a>
      </div>
    </div>
  )
}

export default Container